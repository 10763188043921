var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"general list"},[_c('page-title',{attrs:{"title":_vm.$t('group_menu_list.page_title')}},[_c('template',{slot:"actions"},[_c('LanguageSwitcher')],1)],2),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.isLoading && (!_vm.menu.destinations || !_vm.menu.destinations.length))?_c('v-layout',{staticClass:"row wrap text-center"},[_c('v-flex',{staticClass:"xs12 sw-h5 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t("group_menu_list.empty_list"))+" ")])],1):_vm._e()],1),(_vm.menu.destinations && _vm.menu.destinations.length)?_c('v-layout',{staticClass:"row wrap"},[_c('v-flex',{staticClass:"xs12"},[_c('vue-draggable',{staticClass:"vue-draggable",attrs:{"handle":'.draggable-handle'},on:{"change":_vm.reorderGroupMenuDestinations},model:{value:(_vm.menu.destinations),callback:function ($$v) {_vm.$set(_vm.menu, "destinations", $$v)},expression:"menu.destinations"}},_vm._l((_vm.menu.destinations),function(destination,i){return _c('div',{key:`destination-${i}`},[_c('v-layout',{staticClass:"row align-center py-3"},[(_vm.menu.destinations && _vm.menu.destinations.length)?_c('span',{staticClass:"draggable-handle grey--text"},[_c('font-awesome-icon',{attrs:{"icon":"grip-vertical"}})],1):_vm._e(),_c('v-flex',{staticClass:"xs12 mx-4"},[_c('v-layout',{staticClass:"row align-center"},[_c('IconSelector',{on:{"change":function($event){return _vm.updateDestination(destination, {
                      icon: destination.icon,
                    })}},model:{value:(destination.icon),callback:function ($$v) {_vm.$set(destination, "icon", $$v)},expression:"destination.icon"}}),_c('v-flex',{staticClass:"xs12 ml-4"},[_c('div',[_c('a',{directives:[{name:"show",rawName:"v-show",value:(!destination.is_name_editable),expression:"!destination.is_name_editable"}],staticClass:"sw-h5 font-weight-light sw-primary",on:{"click":function($event){$event.stopPropagation();return _vm.toggleNameEdit(destination, i)}}},[_vm._v(_vm._s(destination.name))]),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(destination.is_name_editable),expression:"destination.is_name_editable"}],ref:"name",refInFor:true,staticClass:"mb-2",attrs:{"label":_vm.$tc('name', 1),"maxlength":"40","hide-details":""},on:{"blur":function($event){return _vm.saveNameEdit(destination, i)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.saveNameEdit(destination, i)}},model:{value:(destination.name),callback:function ($$v) {_vm.$set(destination, "name", $$v)},expression:"destination.name"}})],1)])],1)],1),(_vm.menu.slug === 'ionic-app-sidemenu')?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{staticClass:"ma-0",class:destination.promoted ? 'sw-accent' : 'grey--text',attrs:{"loading":_vm.pendingApiCalls[`destination-${destination.id}`],"icon":""},on:{"click":function($event){return _vm.updateDestination(destination, {
                          promoted: destination.promoted ? 0 : 1,
                        })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(destination.promoted ? "star" : "star_outline")+" ")])],1)],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('app_page.promoted_menu_item'))}})]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-menu',{attrs:{"transition":"slide-y-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"ma-0",attrs:{"icon":"","disabled":_vm.canChangeVisibility(destination.component),"loading":_vm.pendingApiCalls[`destination-${destination.id}`]}},on),[_c('font-awesome-icon',{staticClass:"sw-accent",attrs:{"icon":['fad', 'user-friends']}})],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.accessGroups),function(group,index){return _c('v-list-tile',{key:index,on:{"click":function($event){return _vm.updateDestination(destination, {
                              is_public: group.is_public,
                            })}}},[_c('v-list-tile-title',[_c('v-icon',{staticClass:"mr-2",class:group.is_public === destination.is_public
                                  ? 'sw-accent'
                                  : 'grey--text lighten-1',style:({ marginBottom: '4px' }),attrs:{"small":""}},[_vm._v("fiber_manual_record")]),_vm._v(" "+_vm._s(group.name)+" ")],1)],1)}),1)],1)],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('group_menu_destination_list.is_public_tooltip'))}})])]:_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{staticClass:"ma-0",class:destination.enabled ? 'sw-accent' : 'grey--text',attrs:{"loading":_vm.pendingApiCalls[`destination-${destination.id}`],"icon":""},on:{"click":function($event){return _vm.updateDestination(destination, {
                        enabled: destination.enabled ? 0 : 1,
                      })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(destination.enabled ? "visibility" : "visibility_off")+" ")])],1)],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('group_menu_destination_list.enabled_tooltip'))}})]),_c('v-btn',{staticClass:"ma-0",attrs:{"icon":"","loading":_vm.pendingApiCalls[`destination-${destination.id}`]},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                  name: 'group_menu_destination_edit',
                  params: {
                    menu_id: _vm.menu.id,
                    destination_id: destination.id,
                  },
                })}}},[_c('font-awesome-icon',{staticClass:"sw-accent",attrs:{"icon":"pen"}})],1),_c('v-btn',{staticClass:"ma-0",attrs:{"icon":"","loading":_vm.pendingApiCalls[`destination-${destination.id}`]},on:{"click":function($event){$event.stopPropagation();return _vm.deleteGroupMenuDestination(destination.id)}}},[_c('font-awesome-icon',{staticClass:"sw-accent",attrs:{"icon":"trash"}})],1)],2),_c('v-divider')],1)}),0)],1)],1):_vm._e(),_c('AppSpeedDial',{attrs:{"hint":_vm.$t('group_menu_destination_list.speed_dial_hint')},on:{"click":function($event){return _vm.$router.push({
        name: 'group_menu_destination_create',
        params: { menu_id: _vm.menu.id },
      })}}}),_c('ConfirmationDialog',{ref:"confirmationDialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }